<template>
  <div class='invoice'>
    <van-nav-bar
      title="申请开票"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="100"
    />
    <section>
      <!-- 头部排版 -->
   <div class="content">
      <van-form @submit="onSubmit">
        <div  class="asdad">
          <van-cell-group inset>
          <!-- <van-cell title="发票类型" value="增值税电子普通发票" v-model="formData.invoiceType"/> -->
           <!-- <van-field
            
            input-align="right"
            v-model="formData.invoiceType"
            name="发票类型"
            label="发票类型"
            readonly
          /> -->
          
          <van-field name="radio" label="抬头类型" input-align="right" :rules="[{ required: true, message: '请选择抬头类型' }]">
            <template #input >
              <van-radio-group  v-model="formData.invoiceType" direction="horizontal" >
                <van-radio name="1" @click="geren(formData.invoiceType)">个人</van-radio>
                <van-radio name="2" @click="qiye(formData.invoiceType)">企业</van-radio>
              </van-radio-group>
            </template>
          </van-field>

           <!-- <van-field name="radio" label="发票类容" input-align="right">
            <template #input >
              <van-radio-group  v-model="formData.invoiceTypeContent" direction="horizontal" >
                <van-radio name="0">商品明细</van-radio>
              </van-radio-group>
            </template>
          </van-field> -->

          <van-field
            input-align="right"
            v-model="formData.invoice"
            name="发票抬头"
            label="发票抬头"
            placeholder="请输入发票抬头"
            :rules="[{ required: true}]"
          />

           <van-field
            v-if="formData.invoiceType == 2"
            input-align="right"
            v-model="formData.taxPayer"
            name="单位税号"
            label="单位税号"
            placeholder="请输入单位税号"
            :rules="[{ required: true}]"
          />

          <van-field
            input-align="right"
            v-model="formData.receiverName"
            name="收件人"
            label="收件人"
            placeholder="请输入收件人"
            :rules="[{ required: true}]"
          />

          <van-field
            input-align="right"
            v-model="formData.receiverMobile"
            :maxlength="11"
            name="收件人电话"
            label="收件人电话"
            placeholder="请输入收件人电话"
            :rules="[{ required: true}]"
          />

          <van-field
            input-align="right"
            v-model="formData.receiverEmail"
            name="收件人邮箱"
            label="收件人邮箱"
            placeholder="请输入收件人邮箱"
            :rules="[{ required: true}]"
          />

           <van-field
            v-show="showOpen"
            v-if="formData.invoiceType == 2"
            input-align="right"
            v-model="formData.bank"
            name="开户银行"
            label="开户银行"
            placeholder="请输入开户银行/选填"
          />

           <van-field
            v-show="showOpen"
            v-if="formData.invoiceType == 2"
            input-align="right"
            v-model="formData.bankNumber"
            name="银行账号"
            label="银行账号"
            placeholder="请输入银行账号/选填"
          />

           <van-field
            v-show="showOpen"
            v-if="formData.invoiceType == 2"
            input-align="right"
            v-model="formData.registeredAddress"
            name="企业地址"
            label="企业地址"
            placeholder="请输入企业地址/选填"
          />

           <van-field
            v-show="showOpen"
            v-if="formData.invoiceType == 2"
            input-align="right"
            v-model="formData.registeredMobile"
            name="企业电话"
            label="企业电话"
            placeholder="请输入企业电话/选填"
          />
         
            <van-field
              v-show="showOpen"
              input-align="right"
              v-model="formData.remark"
              rows="2"
              autosize
              label="备注"
              type="textarea"
              maxlength="200"
              placeholder="请输入备注/选填"
              show-word-limit
            />
      
        </van-cell-group>
        <div class="icon-img" @click="clickOpen" v-show="!showOpen">
          <img src="../../assets/images/open.png" alt="">
        </div>
         <div class="icon-img" @click="clickPutAway" v-show="showOpen">
          <img src="../../assets/images/putAway.png" alt="">
        </div>
        </div>
        <div class="submitBtn">
          <van-button round block type="danger" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    </section>
  </div>
</template>

<script>
import Btn from '../../components/common/btn.vue'
export default {
  name: '',
  components: {
    Btn,
  },

  data () {
    return {
       formData: {
        invoiceType: "1",//抬头类型
        details:"",
        invoice:"",//发票抬头
        taxPayer:"",//单位税号
        bank:"",//开户银行
        bankNumber:"",//银行账号
        registeredAddress:"",//企业地址
        registeredMobile:"",//企业电话
        // invoiceType:"增值税电子普通发票",
        remark:"",//备注
        receiverName:"",//收件人
        receiverMobile:"",//收件人电话
        receiverEmail:"",//收件人邮箱
      },
    showOpen:false,
    sku_GoodsList:{},
  
    }
  },
  created () {
    this.sku_GoodsList = JSON.parse(localStorage.getItem("skuGoodsList"));
    // console.log(this.sku_GoodsList[0]);
    this.formData.details = this.sku_GoodsList[0].title + " " + this.sku_GoodsList[0].color + " " + "×" +this.sku_GoodsList[0].num
    
    this.getCacheGet();
    

   },
  mounted () { },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.back(0);
    },
    onSubmit(){

      console.log(this.formData);
      // localStorage.setItem("formData",JSON.stringify(this.formData))

      // 提交缓存发票信息
      // return
      let str = Math.random().toString().slice(-9) //随机生成九位数 235690486
      let arr = str.split('') // 字符串转数组  ["2", "3", "5", "6", "9", "0", "4", "8", "6"]
      function sum(arr) {
          var total = 0;
          if(!arr.length) return
          for (var i = arr.length-1; i>=0; i--) {
              if( arr[i] ){
                  total += Number(arr[i]);
              }
          }
          return total;
      }
      arr[9] = (sum(arr) % 10).toString() // sum(arr)求和 并获取第十位
      let lastStr = arr.join('') // 将获取的十位数数组转字符串
      // console.log(lastStr) // '2356904863'
      localStorage.setItem("randomNumber",lastStr)

      this.$api.order.inertCache({
        accessToken: localStorage.getItem("token"),//需要用户登录的接口
        randomNumber: lastStr,//id
        invoiceUserDO: JSON.stringify(this.formData),//发票信息
      }).then((res) =>{
        if (res.errno === 200) {
          this.$toast("提交成功")
          this.$router.push("/orderconfirm")

        }else{
          this.$toast("提交失败")
        }
      })

    },


    clickOpen(){
      this.showOpen = true;

    },
    clickPutAway(){
      this.showOpen = false;
    },
    
    // 获取缓存发票信息
    getCacheGet(){
      this.$api.order.getCache({
        accessToken:localStorage.getItem("token"),
        randomNumber:localStorage.getItem("randomNumber"),
      }).then((res) =>{
        if (!res.data) {
          // console.log("没有缓存数据");
          this.getInvoiceUserDefultGet();

        }else{
          this.formData = res.data
          // console.log(res,"获取缓存发票信息");
        }
      })
    },
    // 回填数据
    getInvoiceUserDefultGet(){
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          // console.log("没有默认数据");
          return;
          
        }else{
          
          // console.log(res,"发票默认接口");
          this.formData = res.data;
        }
      })
    },
    // 根据单选框回填数据
    geren(invoiceType){
      console.log(this.formData,"个人");

      // return;
      // console.log(invoiceType,"geren");
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          this.formData.invoice = "";
          this.formData.receiverName = "";
          this.formData.receiverMobile = "";
          this.formData.receiverEmail = ""
          return;
        }else{
          // console.log(res);
        let list = res.data;
        // for(let obj in this.formData){
        //   if(!this.formData.obj){
        //       this.formData.obj = list.obj
        //   }
        // }
        this.formData = {
          ...this.formData,
          invoiceType:list.invoiceType,
          invoice:list.invoice,
          receiverName:list.receiverName,
          receiverMobile:list.receiverMobile,
          receiverEmail:list.receiverEmail,
        }
        }
      })
    },
    qiye(invoiceType){
      console.log(this.formData,"企业");
    
      // return;
      // console.log(invoiceType,"qiye");
      this.$api.order.getInvoiceUserDefult({
        accessToken: localStorage.getItem("token"),
        invoiceType: this.formData.invoiceType,
      }).then((res) =>{
        if (!res.data) {
          this.formData.invoice = "";
          this.formData.receiverName = "";
          this.formData.taxPayer = "";
          this.formData.receiverMobile = "";
          this.formData.receiverEmail = ""
          return;
        }else{
          console.log(res);
        let list = res.data;
        //  for(let obj in this.formData){
        //   if(!this.formData.obj){
        //       this.formData.obj = list.obj
        //   }
        // }
        this.formData = {
          ...this.formData,
          invoiceType:list.invoiceType,
          invoice:list.invoice,
          taxPayer:list.taxPayer,
          receiverName:list.receiverName,
          receiverMobile:list.receiverMobile,
          receiverEmail:list.receiverEmail,
        }
        }
      })
    },
  
  },
  // updated:function () {
  //   this.updataUp++; //用于记录更新次数的变量
  //   console.log("用户更改了数据"+this.updataUp);
  // },
  computed: {},
  watch: {},
}
</script>
<style lang="less" scoped>
@import "../../assets/styles/element.less";
.invoice {
  width: 100vw;
  height: 100vh;
  background: #f7f7f7;
}
section {
  position: relative;
  top: 46px;
  height: calc(100vh - 46px);
  display: flex;
  justify-content: center;
  .content{
    width: 100%;
    position: relative;
    padding-top: .22rem /* 11/50 */;
    ::v-deep .van-cell{
          left: 0;
          right: 0;
      .van-radio-group--horizontal{  
        .van-radio--horizontal{
          margin-right: 0;
          margin-left: .24rem /* 12/50 */;
        }
      }
      .van-field__label{
        color: #333;
      }
      .van-radio__icon--checked .van-icon{
        background-color: @radio_checked_color;
        border-color: @radio_checked_color;
      }
    }
      .van-button--danger{
        // background-color: #C83F3F;
        // border: 1px solid #C83F3F;
        // background: #FFFFFF;
        background: @ordinary_btn_color;
        border-radius: .1rem;
        // box-shadow: 0px 0px 20px 0px rgba(7, 63, 53, 0.1);
        font-size: .36rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    .icon-img{
      height: .88rem /* 44/50 */;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      // margin-bottom: .4rem /* 20/50 */;
      img{
        width: .48rem /* 24/50 */;
        height: .48rem /* 24/50 */;
      }
    }
  }
}
.submitBtn{
  position:fixed;
  bottom: .2rem /* 10/50 */;
  left: 0;
  width: 91%;
  margin: 0 .32rem /* 16/50 */ .32rem /* 16/50 */ .32rem /* 16/50 */;
  .btn {
    width: 100%;
      .btnmin(
        @padding:.24rem /* 12/50 */ 0,
        @radius:.64rem /* 32/50 */,
        @size:.32rem /* 16/50 */,
        @bgcolor:@ordinary_btn_color,
        @color:#fff
      );
    }

}
.asdad{
  // height: 80vh;
  overflow-y: scroll;
  margin: 0 .3rem;
  box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .2rem;

}

</style>
